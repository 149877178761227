import { SelectOption } from '../../classes'

export interface IQLBase {
  id?: string
  label: string
  placeholder?: string
  order?: number
  language?: string
  questions?: IQuestion[]
  parent?: string
  requirements?: IQuestionRequirements
  isDeleted?: boolean
}

export interface ILeg extends IQLBase {
  isHidden?: boolean
}

export interface ISection extends IQLBase {
  isHidden?: boolean
  reuseOldAnswers?: boolean
  printInto?: ISectionPrintIntoTypes
  frequency?: ISectionFrequency
  hasDedicatedPage?: boolean
  pdfDocumentConfig?: ISectionPDFDocumentConfig
}

export interface ISectionPDFDocumentConfig {
  fields: ISectionPDFDocumentField[]
  formFile: string
}

export interface ISectionPDFDocumentField {
  name: string
  pageIndex: number
  x: number
  y: number
  type: 'text' | 'radio' | 'date'
  value?: string
}

export interface ISectionPDFDocumentValue {
  pdfFileName: string
  sectionLabel: string
  url: string
}

export type IEnabledIntakeLegs = (string | ILeg)[]
export type IEnabledIntakeSections = (string | ISection)[]

export enum ISectionPrintIntoTypes {
  QUESTIONNAIRE = 'questionnaire',
  REGISTRATION = 'registration',
  PDF = 'pdf',
}

export enum ISectionFrequency {
  ONCE = 'once',
  APPOINTMENT = 'appointment',
  MONTHLY = 'monthly',
  BIANNUALLY = 'biannually',
  ANNUALLY = 'annually',
}

export interface IQuestionRequirements {
  products?: string[]
  excludedProducts?: string[]
  productCategories?: string[]
  excludedProductCategories?: string[]
  consents?: string[]
  facilities?: string[]
  insuranceCarriers?: string[]
  excludedInsuranceCarriers?: string[]
  insuranceTypes?: string[]
  sexes?: string[]
  age?: IQuestionRequirementsAge
  metadata?: IQuestionRequirementsMetadata
}

export type QuestionAgeRequirementComparison = '<=' | '>=' | '=' | 'BETWEEN'

export interface IQuestionRequirementsAge {
  comparison: QuestionAgeRequirementComparison
  months?: number
  min?: number
  max?: number
}

export interface IQuestionMetadataRequirement {
  property: string
  comparison: string
  value: string
}

export interface IDropdownQuestionOtherFieldSettings {
  enabled: boolean
  optionLabel?: string
  fieldLabel?: string
}

export const IDropdownOtherFieldType = 'other'

export interface IDropdownOtherFieldValue {
  type: typeof IDropdownOtherFieldType,
  value: ''
}

export interface IStopQuestionSettings {
  enabled: boolean
  expectedAnswer: string
  title?: string
  message?: string
}

export type IQuestionRequirementsMetadata = IQuestionMetadataRequirement[]

export interface IQuestionOptions {
  'dropdown-opts'?: SelectOption<string>[]
  'table-opts'?: SelectOption<string>[]
  'checkbox-opts'?: string[]
  'radio-opts'?: string[]
  slider?: {
    min: number
    max: number
    minLabel: string
    maxLabel: string
  }
  ui?: string
  minLength?: number
  maxLength?: number
  translate?: boolean
  imageDataURL?: string
  imageURL?: string
  selected?: string[]
  otherFieldSettings?: IDropdownQuestionOtherFieldSettings
  stopQuestionSettings?: IStopQuestionSettings
}
export enum QuestionType {
  Address = 'A',
  Boolean = 'B', // yes / no radio or a checkbox (ui preference is specified in the options)
  Date = 'D',
  Radio = 'R', // radio buttons input (options specified in radio-opts)
  Checkbox = 'C', // checkbox (options specified in checkbox-opts)
  File = 'F', // can upload any file (validators are specified in the options)
  Phone = 'P',
  Measurement = 'M', // weight, height, length, temp, age
  Dropdown = 'S', // aka Select
  Slider = 'SL', // slider
  Text = 'T', // text or textarea (preference is specified in the options)
  Table = 'TA', // table list
  Image = 'I', // draw on image uploaded on S3 with uuid of question as image name under question collection
  BodySelector = 'BS', // body part selector to show where problem is
  Label = 'L', // display only information in intake form
}

export enum QuestionMeasurementType {
  Weight = 'W',
  Length = 'L',
  Temp = 'T',
  Age = 'A',
  Numeric = 'N',
  Ssn = 'S',
}

export interface IQuestion extends IQLBase {
  type: QuestionType
  if?: boolean
  expectedAnswer?: boolean
  translate?: boolean
  measurementType?: QuestionMeasurementType
  required: boolean
  favorite: boolean
  leg?: string
  key?: string
  parent?: string
  hint?: string
  flex?: string
  options?: IQuestionOptions
  isDisabled?: boolean
  reference?: string
  hasDedicatedPage?: boolean
}

export type IQuestionMap = {
  label: string
  questions: IQuestion[]
}[]

export interface IRequirements {
  consents?: { [key: string]: boolean }
  sex?: string
  age?: Date
}
